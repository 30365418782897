import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="feats-item-creation"></a><h2>Feats</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Item Creation</span>
    </h2>
    <h5>BREW POTION [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 3rd.</p>
    <p><b>Benefit</b>: You can create a potion of any 3rd-level or
lower spell that you know and that targets one or more creatures.
Brewing a potion takes one day. When you create a potion, you set the
caster level, which must be sufficient to cast the spell in question
and no higher than your own level. The base price of a potion is its
spell level x its caster level x 50 gp. To brew a potion, you must
spend 1/25 of this base price in XP and use up raw materials costing
one half this base price.</p>
    <p>When you create a potion, you make any choices that you would
normally make when casting the spell. Whoever drinks the potion is the
target of the spell.</p>
    <p>Any potion that stores a spell with a costly material
component or an XP cost also carries a commensurate cost. In addition
to the costs derived from the base price, you must expend the material
component or pay the XP when creating the potion.</p>
    <h5>CRAFT MAGIC ARMS AND ARMOR [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 5th.</p>
    <p><b>Benefit</b>: You can create any magic weapon, armor, or
shield whose prerequisites you meet. Enhancing a weapon, suit of armor,
or shield takes one day for each 1,000 gp in the price of its magical
features. To enhance a weapon, suit of armor, or shield, you must spend
1/25 of its features&#8217; total price in XP and use up raw materials
costing one-half of this total price.</p>
    <p>The weapon, armor, or shield to be enhanced must be a
masterwork item that you provide. Its cost is not included in the above
cost.</p>
    <p>You can also mend a broken magic weapon, suit of armor, or
shield if it is one that you could make. Doing so costs half the XP,
half the raw materials, and half the time it would take to craft that
item in the first place.</p>
    <h5>CRAFT ROD [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 9th.</p>
    <p><b>Benefit</b>: You can create any rod whose prerequisites you
meet. Crafting a rod takes one day for each 1,000 gp in its base price.
To craft a rod, you must spend 1/25 of its base price in XP and use up
raw materials costing one-half of its base price.</p>
    <p>Some rods incur extra costs in material components or XP, as
noted in their descriptions. These costs are in addition to those
derived from the rod&#8217;s base price.</p>
    <h5>CRAFT STAFF [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 12th.</p>
    <p><b>Benefit</b>: You can create any staff whose prerequisites
you meet. Crafting a staff takes one day for each 1,000 gp in its base
price. To craft a staff, you must spend 1/25 of its base price in XP
and use up raw materials costing one-half of its base price. A newly
created staff has 50 charges.</p>
    <p>Some staffs incur extra costs in material components or XP, as
noted in their descriptions. These costs are in addition to those
derived from the staff &#8217;s base price.</p>
    <h5>CRAFT WAND [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 5th.</p>
    <p><b>Benefit</b>: You can create a wand of any 4th-level or
lower spell that you know. Crafting a wand takes one day for each 1,000
gp in its base price. The base price of a wand is its caster level x
the spell level x 750 gp. To craft a wand, you must spend 1/25 of this
base price in XP and use up raw materials costing one-half of this base
price. A newly created wand has 50 charges.</p>
    <p>Any wand that stores a spell with a costly material component
or an XP cost also carries a commensurate cost. In addition to the cost
derived from the base price, you must expend fifty copies of the
material component or pay fifty times the XP cost.</p>
    <h5>CRAFT WONDROUS ITEM [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 3rd.</p>
    <p><b>Benefit</b>: You can create any wondrous item whose
prerequisites you meet. Enchanting a wondrous item takes one day for
each 1,000 gp in its price. To enchant a wondrous item, you must spend
1/25 of the item&#8217;s price in XP and use up raw materials costing half of
this price.</p>
    <p>You can also mend a broken wondrous item if it is one that you
could make. Doing so costs half the XP, half the raw materials, and
half the time it would take to craft that item in the first place.</p>
    <p>Some wondrous items incur extra costs in material components
or XP, as noted in their descriptions. These costs are in addition to
those derived from the item&#8217;s base price. You must pay such a cost to
create an item or to mend a broken one.</p>
    <h5>FORGE RING [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 12th.</p>
    <p><b>Benefit</b>: You can create any ring whose prerequisites
you meet. Crafting a ring takes one day for each 1,000 gp in its base
price. To craft a ring, you must spend 1/25 of its base price in XP and
use up raw materials costing one-half of its base price.</p>
    <p>You can also mend a broken ring if it is one that you could
make. Doing so costs half the XP, half the raw materials, and half the
time it would take to forge that ring in the first place.</p>
    <p>Some magic rings incur extra costs in material components or
XP, as noted in their descriptions. You must pay such a cost to forge
such a ring or to mend a broken one.</p>
    <h5>SCRIBE SCROLL [ITEM CREATION]</h5>
    <p className="initial"><b>Prerequisite</b>: Caster level 1st.</p>
    <p><b>Benefit</b>: You can create a scroll of any spell that you
know. Scribing a scroll takes one day for each 1,000 gp in its base
price. The base price of a scroll is its spell level x its caster level
x 25 gp. To scribe a scroll, you must spend 1/25 of this base price in
XP and use up raw materials costing one-half of this base price.</p>
    <p>Any scroll that stores a spell with a costly material
component or an XP cost also carries a commensurate cost. In addition
to the costs derived from the base price, you must expend the material
component or pay the XP when scribing the scroll.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      